<template>
  <div class="goldie-silversaver">
    <div class="codenotexpired" v-if="!code_expired">
      <div class="notvalid" v-if="mode == 'notvalid'">
        <h1>
          {{ texts.page_not_found[store.state.lang] }}
        </h1>
        {{ texts.redirecting_to[store.state.lang] }} <a :href="GoldieUrl">Goldie Silversaver</a>
      </div>
      <div class="newsubscription" v-if="mode == 'newsubscription'">
        <div ref="checkoutElement" class="checkout">
          <!-- Stripe Checkout form will be mounted here -->
        </div>
        <!-- <button class="backbtn" @click="backToGoldie" @keydown.enter="backToGoldie" @keydown.space="backToGoldie">
          {{ texts.back[store.state.lang] }}
        </button> -->
      </div>
      <div class="activesubscrition" v-if="mode == 'activesubscrition'">
        <div class="card" v-show="subscription.name">
          <img :src="subscription.image" alt="Subscription Image" class="card-img">
          <div class="card-content">
            <h3>{{ subscription.name }}</h3>
            <p>{{ texts.monthly_payment[store.state.lang] }}: <strong>{{ subscription.price }}</strong></p>
            <p>{{ texts.valid_until[store.state.lang] }}: <strong>{{ subscription.valid_until }}</strong></p>
            <p>{{ texts.client[store.state.lang] }}: <strong>{{ subscription.email }}</strong></p>
            <div class="cnlbtncon">
              <button class="cnlbtn" @click="cancelSubscription">{{ texts.cancel_subscription[store.state.lang]
                }}</button>
              <hr />
              <button class="backbtn" @click="backToGoldie" @keydown.enter="backToGoldie" @keydown.space="backToGoldie">
                {{ texts.back[store.state.lang] }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cancelsubscription" v-if="mode == 'cancelsubscription'">
      <div class="card">
        <h2 class="canceltexts">
          {{ texts.canceled[store.state.lang] }}
        </h2>
        <p class="canceltexts">
          {{ texts.canceled_explain[store.state.lang] }}
        </p>
        <p class="canceltexts">
          {{ subscription_expires_on }}
        </p>
        <p class="canceltexts">
          {{ texts.redirecting_to[store.state.lang] }} <a :href="GoldieUrl">Goldie Silversaver</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>

import { ref, nextTick, onMounted, onUnmounted, watchEffect } from 'vue';
import router from '@/router';
import utils from '@/utils';
import texts from "@/assets/translations/All.json"
import { useStore } from 'vuex';

const store = useStore();

const confirmation_code = ref()
const mode = ref("")
const checkoutElement = ref();
confirmation_code.value = router.currentRoute.value.query.k8i7uo6g;

const code_expired = ref(false)
const subscription_expires_on = ref('')
const code_created_at = ref(10 ^ 10)

const GoldieUrl = ref(process.env.VUE_APP_GOLDIE_RETURN_URL)

const subscription = ref({
  name: '',
  image: '',
  valid_until: '',
  price: '',
  email: ''
});

const backToGoldie = () => {
  if (!ti == undefined) {
    clearInterval(ti);
  }
  window.location.href = process.env.VUE_APP_GOLDIE_RETURN_URL
}


function cancelSubscription() {
  utils.methods.be("j2injf4l", { "t2kdszzj": confirmation_code.value }).then((cancelresp) => {
    if (cancelresp.canceled == 1) {
      mode.value = "cancelsubscription"
      subscription_expires_on.value = utils.methods.formatTimestampToYMD(cancelresp.until)
      if (!ti == undefined) {
        clearInterval(ti);
      }
    } else {
      if (!ti == undefined) {
        clearInterval(ti);
      }
      mode.value = "notvalid"
    }
  }).catch((cancelerr) => {
    if (!ti == undefined) {
      clearInterval(ti);
    }
    mode.value = "notvalid"
  })
}

const ti = setInterval(() => {
  code_expired.value = ((Date.now() / 1000) - code_created_at.value) > 2700
  if (code_expired.value && mode.value != 'cancelsubscription' && subscription.name) {
    if (!ti == undefined) {
      clearInterval(ti);
    }
    window.location = process.env.VUE_APP_GOLDIE_RETURN_URL
  }
}, 1000)



onUnmounted(() => {
  if (!ti == undefined) {
    clearInterval(ti);
  }
})

mode.value == 'newsubscription'

watchEffect(() => {
  let md = mode.value
  if (md == "notvalid") {
    setTimeout(() => {
      if (!ti == undefined) {
        clearInterval(ti);
      }
      window.location = process.env.VUE_APP_GOLDIE_RETURN_URL
    }, 5000)
  } else if (md == "cancelsubscription") {
    setTimeout(() => {
      if (!ti == undefined) {
        clearInterval(ti);
      }
      window.location = process.env.VUE_APP_GOLDIE_RETURN_URL
    }, 20000)
  }
})

onMounted(() => {
  ///
  window.location = process.env.VUE_APP_GOLDIE_RETURN_URL
  ///
  utils.methods.be("i78jm7g1", { "w8vuyoai": confirmation_code.value }).then((confcoderesp) => {
    if (confcoderesp.user_subscription.preferredLanguage) {
      store.state.lang = confcoderesp.user_subscription.preferredLanguage
    }
    if (confcoderesp.user_subscription.codeCreatedAt) {
      code_created_at.value = confcoderesp.user_subscription.codeCreatedAt
    }
    if (!confcoderesp.user_subscription.userId) {
      if (!ti == undefined) {
        clearInterval(ti);
      }
      mode.value = "notvalid"
    } else if (confcoderesp.user_subscription.activeStripeSubscriptionId) {
      if (confcoderesp.user_subscription.activeStripeSubscriptionId == "toosoon") {
        backToGoldie()
      }
      mode.value = "activesubscrition"
    } else if (confcoderesp.user_subscription.stripeSessionId && confcoderesp.user_subscription.stripeClientSecret) {
      mode.value = "newsubscription"
    } else {
      if (!ti == undefined) {
        clearInterval(ti);
      }
      mode.value = "notvalid"
    }
    if (mode.value == 'newsubscription') {
      const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      nextTick(() => {
        const checkout = stripe.initEmbeddedCheckout({
          clientSecret: confcoderesp.user_subscription.stripeClientSecret,
        })
        checkout.then((co) => {
          co.mount(checkoutElement.value)
        })
      })
    }
    else if (mode.value == 'activesubscrition') {
      utils.methods.be("c9bszomg", { "o1lu6k2u": confirmation_code.value }).then((subsresp) => {
        if (subsresp.subscription) {
          subscription.value = subsresp.subscription
        } else {
          if (!ti == undefined) {
            clearInterval(ti);
          }
          mode.value = "notvalid"
        }
      }).catch((subserr) => {
        if (!ti == undefined) {
          clearInterval(ti);
        }
        mode.value = "notvalid"
      })
    }
  }).catch((confcodeerr) => {
    if (!ti == undefined) {
      clearInterval(ti);
    }
    mode.value = "notvalid"
  })
})


</script>

<style scoped>
.notvalid {
  padding-top: 50px;
}

.backbtn {
  margin-top: 40px;
  padding: 10px;
  background-color: #455C84;
  color: white;
  font-weight: 500;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
}

.backbtn:hover {
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
  /* transform: translateY(0.4px); */
}

.checkout {
  margin-left: auto;
  margin-right: auto;
}

.card {
  color: #455C84;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
}

.card-img {
  width: 60%;
  border-radius: 8px;
  margin-bottom: 2vh;
}

.card-content {
  text-align: left;
  margin-top: 10px;
}

.cnlbtncon {
  text-align: center;
}

.card-content h3 {
  margin-bottom: 20px;
  color: #455C84;
}

.card-content p {
  margin: 10px 0;
  color: #455C84;
}

.cnlbtn {
  background-color: #FA6E59;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 10px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  font-size: 14px;
}

.cnlbtn:hover {
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.2);
}

.canceltexts {
  text-align: left;
  padding: 1vw;
}
</style>