<template>
  <div class="page404">
    <h1>
      {{texts.page_not_found[store.state.lang]}}
    </h1>
    {{texts.redirecting_to[store.state.lang]}} <a :href="GoldieUrl">Goldie Silversaver</a>
  </div>
</template>

<script setup>
import texts from "@/assets/translations/All.json"
import { useStore } from 'vuex';
import { ref } from 'vue';

const store = useStore();

const GoldieUrl = ref(process.env.VUE_APP_GOLDIE_RETURN_URL)
setTimeout(() => {
  window.location = GoldieUrl.value
}, 5000)

</script>
<style scoped>
.page404 {
  padding-top: 50px;
}
</style>